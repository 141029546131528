exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-docs-how-it-works-jsx": () => import("./../../../src/pages/docs/how_it_works.jsx" /* webpackChunkName: "component---src-pages-docs-how-it-works-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-popular-languages-jsx": () => import("./../../../src/pages/popular_languages.jsx" /* webpackChunkName: "component---src-pages-popular-languages-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-tos-jsx": () => import("./../../../src/pages/tos.jsx" /* webpackChunkName: "component---src-pages-tos-jsx" */),
  "component---src-templates-blog-layout-jsx-content-file-path-content-blog-how-to-create-language-switcher-mdx": () => import("./../../../src/templates/BlogLayout.jsx?__contentFilePath=/opt/render/project/src/content/blog/how_to_create_language_switcher.mdx" /* webpackChunkName: "component---src-templates-blog-layout-jsx-content-file-path-content-blog-how-to-create-language-switcher-mdx" */),
  "component---src-templates-blog-layout-jsx-content-file-path-content-blog-how-to-localize-mdx": () => import("./../../../src/templates/BlogLayout.jsx?__contentFilePath=/opt/render/project/src/content/blog/how_to_localize.mdx" /* webpackChunkName: "component---src-templates-blog-layout-jsx-content-file-path-content-blog-how-to-localize-mdx" */),
  "component---src-templates-translation-index-layout-jsx": () => import("./../../../src/templates/TranslationIndexLayout.jsx" /* webpackChunkName: "component---src-templates-translation-index-layout-jsx" */),
  "component---src-templates-translation-layout-jsx-content-file-path-null": () => import("./../../../src/templates/TranslationLayout.jsx?__contentFilePath=null" /* webpackChunkName: "component---src-templates-translation-layout-jsx-content-file-path-null" */)
}

